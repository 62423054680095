import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Tech from "./Tech";
import Work from "./Work";
import BlogCollection from "./BlogCollection";
import BlogPost from "./BlogPost"; // Import the BlogPost component
import SignUp from "./SignUp"; // Import the BlogPost component

import ScrollToTop from "./ScrollToTop.js";

const Router = () => (
  <BrowserRouter>
    <ScrollToTop />

    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/#process" element={<App />} />
      <Route path="/tech" element={<Tech />} />
      <Route path="/work" element={<Work />} />
      <Route path="/blog" element={<BlogCollection />} />
      <Route path="/blog/:id" element={<BlogPost />} />
      <Route path="/community" element={<SignUp />} />
    </Routes>
    {/* <div id="footer" className=" text-center">
      <p>Satori AI &copy; 2023</p>
    </div> */}
  </BrowserRouter>
);

export default Router;
