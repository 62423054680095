import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import JsonData from "./data/data.json";
import { Link } from "react-router-dom";

const BlogCollection = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    setBlogData(JsonData.Blogs);
  }, []);

  if (!blogData) return null;

  return (
    <div id="team" className="">
      <Navigation className="h-15" />
      <div className="container">
        <h2>Blog</h2>
        <div className="rowBlog">
          {blogData.map((blog, index) => (
            <div className="blogCardContainer col-lg-4 col-md-4 col-sm-12" key={index}>
              <div className="blogCard h-full flex flex-col justify-between">
                <Link to={`/blog/${blog.id}`} className="cursor-pointer">
                  <div className="cardContent flex flex-col h-full">
                    <div className="w-full">
                      <figure className="">
                        <img loading="eager" src={`/img/${blog.image}`} alt={blog.title} style={{ width: "100%", height: "250px" }} className="" />
                      </figure>
                    </div>
                    <div className="w-full description flex flex-col flex-grow justify-between">
                      <div className="textContainer">
                        <a href={`/blog/${index}`}>
                          <div className="space-y-2">
                            <h3 className="blogTitle">{blog.title}</h3>
                            <div className="dateContainer mt-5">
                              <p className="date">{blog.date}</p>
                            </div>
                            <p className="" style={{ fontSize: "16px" }}>
                              {blog.subtitle}
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogCollection;
