import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { CaseStudiesSection } from "./components/CaseStudiesSection.jsx";
import { Process } from "./components/Process";
import { Definition } from "./components/Definition";
import { Contact } from "./components/contact";

import { Link } from "react-router-dom";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	offset: 50,
});

const App = () => {
	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);

	return (
		<div className="flex flex-col h-screen">
			<Navigation className="h-15" />

			<Header data={landingPageData.Header} />
			<Definition />
			<hr />

			<Process data={landingPageData.Process} />
			<hr />

			<div id="services">
				{/* Desktop */}

				<div className="hidden-xs">
					<div id="base">
						<div className="container bodyhome desktop">
							<div class="myrowdesktop">
								<div className="col-sm-6 col-xs-12 leftside segHome">
									<h1 class="large-font">Dataset Curation</h1>

									<div className="case_study_description">
										<p>
											Never <i>"one dataset fits all"</i>.
										</p>
										<p>We build datasets from sources of your choice, that fit the specific needs of your research project.</p>
									</div>
								</div>
								<div className="col-sm-6 col-xs-12 rightside segHome">
									<div className="col-sm-12 col-xs-12">
										<picture>
											{/* <source className="team-img no-border" type="image/avif" srcset="/img/dataset.avif" /> */}
											<img alt="..." className="team-img no-border" src="img/dataset.png" />
										</picture>
									</div>
								</div>
								<div className="center-line-div top"></div>
							</div>
						</div>
						<div className="container bodyhome">
							<div class="myrowdesktop">
								<div className="col-sm-6 col-xs-12 leftside segHome">
									<div className="col-sm-12 col-xs-12">
										<picture>
											{/* <source className="team-img no-border" type="image/avif" srcset="/img/nn.avif" /> */}
											<img alt="..." className="team-img no-border" src="img/nn.png" />
										</picture>
									</div>
								</div>
								<div className="col-sm-6 col-xs-12 rightside segHome">
									<h1>Bespoke AI Systems</h1>

									<div className="case_study_description">
										<p>We draw on a plethora of constantly-improving technologies to build AI systems that make sense for you.</p>
										<p>
											Find out more about our dedication to staying one step ahead: <br />
										</p>
									</div>
									<Link to="/tech#header">
										<button type="submit" id="tech" class="btn btn-custom btn-lg btn-blue-background" href="/tech">
											Our Tech
										</button>
									</Link>
								</div>
								<div className="center-line-div mid"></div>
							</div>
						</div>
						<div className="container bodyhome">
							<div class="myrowdesktop">
								<div className="col-sm-6 col-xs-12 leftside segHome">
									<h1>Visualisations & Insights</h1>

									<div className="case_study_description">
										<p>
											Outputs from our AI systems are compiled and delivered in the form of interactive data visualisations and
											actionable insight reports.
										</p>
									</div>
								</div>

								<div className="col-sm-6 col-xs-12 rightside segHome">
									<div className="col-sm-12 col-xs-12">
										<picture>
											{/* <source className="team-img no-border" type="image/avif" srcset="/img/viz.avif" /> */}
											<img alt="..." className="team-img no-border" src="img/viz.png" />
										</picture>
									</div>
								</div>
								<div className="center-line-div bot"></div>
							</div>
						</div>
					</div>
				</div>

				{/* Mobile */}
				<div className="visible-xs">
					<div className="container ">
						<h1 className="medium-font text-center">Dataset Curation</h1>
						<div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
							<picture>
								{/* <source className="team-img no-border" type="image/avif" srcset="/img/viz.avif" /> */}
								<img alt="..." className="team-img no-border" src="img/dataset.png" />
							</picture>
						</div>
						<div className="case_study_description">
							<p>
								Never <i>"one dataset fits all"</i>.
							</p>
							<p>We build datasets from sources of your choice, that fit the specific needs of your research project.</p>
						</div>
					</div>
					<hr />
					<div className="container ">
						<h1 className="medium-font text-center">Bespoke AI Systems</h1>
						<div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
							<picture>
								{/* <source className="team-img no-border" type="image/avif" srcset="/img/nn.avif" /> */}
								<img alt="..." className="team-img no-border" src="img/nn.png" />
							</picture>
						</div>
						<div className="case_study_description">
							<p>We draw on a plethora of constantly-improving technologies to build AI systems that make sense for you.</p>
							<p>
								Find out more process our dedication to staying one step ahead. <br />
								<div className="div text-center">
									<Link to="/tech#header" className="text-center">
										<button type="submit" id="tech" class="btn btn-custom btn-lg btn-blue-background" href="/tech">
											Our Tech
										</button>
									</Link>
								</div>
							</p>
						</div>
					</div>
					<hr />
					<div className="container ">
						<h1 className="medium-font text-center">Visualisations & Insights</h1>
						<div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
							<picture>
								{/* <source className="team-img no-border" type="image/avif" srcset="/img/viz.avif" /> */}
								<img alt="..." className="team-img no-border" src="img/viz.png" />
							</picture>
						</div>
						<div className="case_study_description">
							<p>
								Outputs from our AI systems are compiled and delivered in the form of interactive data visualisations and actionable
								insight reports.
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* <hr /> */}
			<hr />

			<CaseStudiesSection />
			<hr />

			<Contact data={landingPageData.Contact} />
		</div>
	);
};

export default App;
