import { useState } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setState({ ...initialState });
    setIsSuccess(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_rtpoedm", "template_tjut115", e.target, "KINhxyLw9yaxWyCZc").then(
      (result) => {
        console.log(result.text);
        clearState();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div id="contact" style={{ paddingTop: "10vh" }}>
      <div id="">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h1 className="visible-xs medium-font">Get In Touch</h1>
                <h1 className="hidden-xs ">Get In Touch</h1>
                {/* <h1></h1> */}
                <p>Please fill out the form below or send an email to info@satori-ai.com and we will get back to you as soon as possible.</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" id="name" name="name" className="form-control" placeholder="Name" required onChange={handleChange} />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="email" id="email" name="email" className="form-control" placeholder="Email" required onChange={handleChange} />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea name="message" id="message" className="form-control" rows="4" placeholder="Message" required onChange={handleChange}></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success">
                  {isSuccess && (
                    <div className="alert alert-success" role="alert">
                      Message sent successfully, we'll get back to you soon!
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-custom btn-lg btn-blue-background">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
