import React, { useState } from "react";
import { Navigation } from "./components/navigation";

const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedTag, setSelectedTag] = useState(""); // For selected tag
  const predefinedTags = ["developer", "brand", "agency"]; // Tags list
  const [signupStatus, setSignupStatus] = useState(null); // "success", "error", or null
  const [signupError, setSignupError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://82h6c0kpj6.execute-api.us-east-1.amazonaws.com/default/mailchimp", {
        method: "POST",
        body: JSON.stringify({ email, firstName, lastName, customerTags: [selectedTag] }),
      });
      const data = await response.json();
      console.log(data);
      if ("error" in data) {
        setSignupStatus("error");
        setSignupError(data.error);
      } else {
        setSignupStatus("success");
      }

      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error:", error);
      setSignupStatus("error");
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="flex flex-col h-screen SignUp">
      <Navigation className="h-15" />

      <header id="signup" style={{ height: "100vh" }}>
        <div className="col-md-4 col-md-offset-4  text-center" style={{ paddingTop: "-5vh", height: "100%" }}>
          <div id="toCenterVertically">
            <h2>Join our community! </h2>
            <p>When it comes to AI, there's a LOT of noise out there.</p>
            <p>Everything we share is easily digestible and comes from technical AI specialists.</p>
            <form onSubmit={handleSubmit}>
              <div className="inputs">
                <input type="email" className="form-control" placeholder="Email*" value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                {/* <select className="form-control" value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
                  <option value="">Select Tag (Optional)</option>
                  {predefinedTags.map((tag) => (
                    <option key={tag} value={tag}>
                      {tag}
                    </option>
                  ))}
                </select> */}
              </div>
              <button type="submit" className="btn btn-custom btn-lg btn-white-background page-scroll pt-5">
                Join!
              </button>
            </form>
            <div style={{ marginTop: "10px" }}>
              {signupStatus === "success" && <p>Signup successful!</p>}
              {signupStatus === "error" && <p>{signupError}</p>}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default SignupForm;
