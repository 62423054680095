export const Process = (props) => {
	return (
		<div id="process" className=" text-center section" style={{ height: "80vh" }}>
			{/* Mobile */}
			<div className=" visible-xs" style={{ paddingTop: 0 }}>
				<div class=" col-md-10 col-md-offset-1 one-pager ">
					<div class="">
						{/* <h1 class="medium-font">Our Process</h1> */}
						<div className="col-xs-12">
							<div className="process-text">
								<h5>
									At Satori AI, we are committed to providing you with the latest advancements in artificial intelligence for market
									research by continuously upgrading our technology.
								</h5>
								<h5>
									We use purpose-built datasets, bespoke AI models, and beautiful visualisations to provide the most accurate and
									comprehensive consumer insights at scale.
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Desktop */}
			<div className="container hidden-xs ccme">
				<div class="col-md-10 col-md-offset-1">
					{/* <h1 class="large-font">Our Process</h1> */}
					<div className="col-xs-12">
						<div className="process-text desktop">
							<h5>
								At Satori AI, we are committed to providing you with the latest advancements in artificial intelligence for market
								research by continuously upgrading our technology.
							</h5>
							<h5>
								We use purpose-built datasets, bespoke AI models, and beautiful visualisations to provide the most accurate and
								comprehensive consumer insights at scale.
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
