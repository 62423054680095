import { Link } from "react-router-dom";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a href="/">
            <div class="logo-container">
              <img src="/img/satori_logo.svg" class="img-responsive logo" alt="logo" />
              <a class="navbar-brand page-scroll" href="/">
                Satori AI
              </a>
            </div>
          </a>
          {/* <Link to="/"> */}

          {/* </Link> */}
        </div>

        {/* Mobile */}
        <div className="collapse navbar-collapse " id="bs-example-navbar-collapse-1">
          {" "}
          <ul className="nav navbar-nav text-center">
            <li className="mx-auto">
              <a href="/#process" className="page-scroll">
                Process
              </a>
            </li>
            <li className="mx-auto">
              <a href="/tech">Tech</a>
            </li>
            <li className="mx-auto">
              <a href="/work" className="page-scroll">
                Work
              </a>
            </li>
            <li className="mx-auto">
              <a href="/blog" className="page-scroll">
                Blog
              </a>
            </li>
            <li className="mx-auto">
              {/* visible-xs"> */}
              <a href="/#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li className="mx-auto">
              {/* visible-xs"> */}
              <a href="/community" className="page-scroll">
                Community
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
