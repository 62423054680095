import { Link } from "react-router-dom";

export const CaseStudiesSection = (props) => {
	return (
		<div id="casestudies">
			<div className="container text-center section">
				<h1 className="visible-xs medium-font">Case Studies</h1>
				<h1 className="hidden-xs ">Case Studies</h1>

				{/* Mobile */}
				<div className="grid grid-cols-3 visible-xs">
					<div className="col-md-4">
						<div className="card page-scroll">
							{/* <picture> */}
							{/* <source className="case-img no-border case-img" type="image/avif" srcset="/img/case-beauty.avif" /> */}
							{/* <img alt="..." className=" no-border case-img" src="img/case-beauty.png" /> */}
							{/* </picture> */}
							<img alt="..." className=" no-border case-img" src="img/case-beauty.png" />
							<div className="buttonForCase">
								<a className="navbar-brand page-scroll button" href="work#aging">
									Beauty
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card page-scroll">
							{/* <picture> */}
							{/* <source className=" no-border case-img" type="image/avif" srcset="/img/case-tech.avif" /> */}
							{/* <img alt="..." className=" no-border case-img" src="img/case-tech.png" /> */}
							{/* </picture> */}
							<img alt="..." className=" no-border case-img" src="img/case-tech.png" />

							<div className="buttonForCase">
								<a className="navbar-brand page-scroll button" href="work#tech">
									Tech
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card page-scroll">
							{/* <picture> */}
							{/* <source className="no-border case-img" type="image/avif" srcSet="/img/case-bank.avif" /> */}
							{/* <img alt="..." className="no-border case-img" src="img/case-bank.png" /> */}
							{/* </picture>{" "} */}
							<img alt="..." className="no-border case-img" src="img/case-bank.png" />
							<div className="buttonForCase">
								<a className="navbar-brand page-scroll button" href="work#bank">
									Banking
								</a>
							</div>
						</div>
					</div>
				</div>

				{/* Desktop */}
				<div className="d-flex justify-content-center hidden-xs">
					<div className="grid grid-cols-3">
						<div className="col-md-4">
							<div className="card page-scroll">
								<picture>
									{/* <source className="case-img no-border case-img" type="image/avif" srcset="/img/case-beauty.avif" /> */}
									<img alt="..." className=" no-border case-img" src="img/case-beauty.png" />
								</picture>
								<div className="buttonForCase">
									<a className="navbar-brand page-scroll button" href="work#aging">
										Beauty
									</a>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card page-scroll">
								<picture>
									{/* <source className=" no-border case-img" type="image/avif" srcset="/img/case-tech.avif" /> */}
									<img alt="..." className=" no-border case-img" src="img/case-tech.png" />
								</picture>

								<div className="buttonForCase">
									<a className="navbar-brand page-scroll button" href="work#tech">
										Tech
									</a>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card page-scroll">
								<picture>
									{/* <source className="no-border case-img" type="image/avif" srcSet="/img/case-bank.avif" /> */}
									<img alt="..." className="no-border case-img" src="img/case-bank.png" />
								</picture>{" "}
								<div className="buttonForCase">
									<a className="navbar-brand page-scroll button" href="work#bank">
										Banking
									</a>
								</div>
								{/* <div className="buttonForCase">
									<Link to="/work#header">
										<button type="submit" className="btn btn-custom btn-lg btn-white-background caseButton" href="/tech">
											Banking
										</button>
									</Link>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
