import { Link } from "react-router-dom";

export const Header = (props) => {
	return (
		<header id="header" style={{ height: "100vh" }}>
			<div class="col-md-10 col-md-offset-1 text-left centerme" style={{ paddingTop: "10vh" }}>
				<h1 class="visible-xs-block text-center medium-font">Artificial Intelligence for Market Research</h1>
				<h1 class="hidden-xs large-font">Artificial Intelligence for Market Research</h1>
				{/* <div id="strapline"> */}
				<h5 class="visible-xs-block text-center ">Discover Insights Beyond Human Capabilities</h5>
				<h5 class="hidden-xs text-left">Discover Insights Beyond Human Capabilities</h5>
				{/* </div> */}
				<div class="text-center visible-xs-block">
					<a href="#contact" className="btn btn-custom btn-lg btn-white-background page-scroll">
						Contact Us
					</a>
				</div>
				<div class="hidden-xs text-left">
					<a href="#contact" className="btn btn-custom btn-lg btn-white-background page-scroll">
						Contact Us
					</a>
				</div>
			</div>
		</header>
	);
};
