import { Navigation } from "./components/navigation";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Work = () => {
  return (
    <div>
      <div className="flex flex-col h-screen">
        <Navigation className="h-15" />
        {/* Desktop */}
        <div className="hidden-xs">
          <header id="header" style={{ height: "100vh" }}>
            <div class="col-md-10 col-md-offset-1 text-left" style={{ paddingTop: "20vh", marginBottom: "-30vh" }}>
              <h1 class="large-font">Our Work</h1>
              <div class="col-md-6 ">
                <h5 class="text-left" style={{ paddingTop: "10px" }}>
                  We work in partnership with research agencies and directly with brands. We specialise in working with novel datasets on projects that benefit from agile AI solutions.
                </h5>
                <h5 class="text-left " style={{ paddingTop: "10px" }}>
                  If you have a research project that you feel could benefit from AI technology, however unstructured and loosely defined, we can help you.
                </h5>

                <h5 class="text-left ">
                  Below, you can find details of our work for a <strong>World Leading Beauty Conglomerate, a Top 10 Tech Company, and an American Bank.</strong>
                </h5>
              </div>
            </div>
          </header>
          <div>
            <div id="aging" className="container body page-scroll">
              <div class="myrow">
                <div className="col-sm-6 col-xs-12 leftside seg " style={{ marginTop: "-14vh", marginBottom: "-14vh", height: "105%" }}>
                  <h3 class="large-font">Reading Consumer Emotions around Anti-Aging</h3>

                  <div class="case_study_description">
                    <p>
                      <strong>The Challenge</strong>: One of the world’s leading beauty conglomerates sought to radically update their understanding of the anti-aging themes, opinions and pain points of female consumers.
                    </p>
                    <p>
                      <strong>Our Approach</strong>: We created a dataset with text data from 60,000 skincare enthusiasts. Then, we used three complementary AI techniques: Topic Clustering, Emotion Recognition, and Trend Forecasting. By leveraging multiple approaches, we are able to obtain a more
                      comprehensive understanding of the topic at hand.
                    </p>
                    <p>
                      <strong>The Outcome</strong>: Our study yielded valuable insights into the emotional journey that consumers undergo during the aging process. Additionally, we pinpointed two specific ingredients that were causing confusion for consumers in anti-aging skincare products.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12 rightside seg" style={{ marginTop: "-20vh", marginBottom: "-20vh", height: "125%" }}>
                  <div className="col-sm-12 col-xs-12" style={{ textAlign: "center" }}>
                    <img src="img/emotion.png" alt="..." class="tech-img" />
                    <div className="caption">
                      <p>Emotion Recognition Signature in Anti-Aging Discussion</p>
                    </div>
                  </div>
                </div>
                <div className="center-line-div top"></div>
              </div>
            </div>
            <div id="tech" className="container body page-scroll" style={{ marginTop: "-14vh" }}>
              <div class="myrow">
                <div className="col-sm-6 col-xs-12 leftside seg">
                  <div className="col-sm-12 col-xs-12 blah">
                    <img src="img/NER.png" alt="..." className="tech-img nobord" style={{ marginTop: "14vh" }} />
                    <div className="caption">
                      <p>Named Entity Recognition to Detect Peak Moments</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12 rightside seg">
                  <h3 class="hidden-xs large-font">Detecting Memorable Moments In Productivity Apps</h3>

                  <div class="case_study_description">
                    <p>
                      <strong>The Challenge</strong>: A top-10 global tech company wanted to detect peak emotional moments in the user experience of productivity apps. They sought to understand the factors that caused these memorable moments in competitor apps and how they could replicate it
                      themselves.
                    </p>
                    <br />
                    <p>
                      <strong>Our Approach</strong>: To tackle this, we employed three AI techniques: Named Entity Recognition, Topic Clustering, and Emotion Recognition. Our system processed over 200,000 data points and extracted the 500 most highly emotional memories.
                    </p>
                    <br />
                    <p>
                      <strong>The Outcome</strong>: We detected peak moments across a wide range of environments in competitor apps, which was an exciting discovery for our client. We also identified that certain emotions, such as pride and relief, were often heightened. By analysing the features
                      that contributed to these peaks, we provided recommendations to our client, which their engineering team is now acting on.
                    </p>
                  </div>
                </div>
                <div className="center-line-div mid"></div>
              </div>
            </div>
            <div id="bank" className="container body page-scroll" style={{}}>
              <div class="myrow">
                <div className="col-sm-6 col-xs-12 leftside seg">
                  <h3 class="hidden-xs large-font">Measuring and Improving the D&I Perception of a Bank</h3>
                  <div class="case_study_description">
                    <p>
                      <strong>The Challenge</strong>: A leading American bank approached us to enhance its Diversity and Inclusion image and gain a better understanding of D&I in the banking industry. Our goal was to conduct a finance-focussed analysis of diversity-related conversations online.
                    </p>
                    <p>
                      <strong>Our Approach</strong>: We divided our approach into three scopes: Diversity Landscape, Brands Landscape, and D&I in Banking Deepdive. We leveraged 5 million data points and utilised three AI techniques: Topic Clustering, Sentiment Analysis, and Trend Forecasting.
                    </p>
                    <p>
                      <strong>The Outcome</strong>: Our research revealed that banks face a unique challenge in promoting themselves as diverse. We identified the primary issues that caused feelings of neglect to the BIPOC community in banking. Our findings informed a long-term improvement plan,
                      which the bank is currently implementing to establish diverse practices internally and communicate effectively externally.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 col-xs-12 rightside seg">
                  <div className="col-sm-12 col-xs-12">
                    <img src="img/bank-deepdive.png" alt="..." class="tech-img nobord" style={{ marginTop: "14vh" }} />
                    <div className="caption">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="center-line-div bot" style={{ height: "70vh" }}></div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile */}
        <div className="visible-xs">
          <header id="header" style={{ height: "100vh" }}>
            <div class="text-left container mob" style={{ paddingTop: "15vh" }}>
              <h1 class="medium-font text-center">Our Work</h1>

              <h5 class="medium-font text-left " style={{ paddingTop: "10px" }}>
                We work in partnership with research agencies and directly with brands. We specialise in working with novel datasets on projects that benefit from agile AI solutions.
              </h5>
              <h5 class="medium-font text-left " style={{ paddingTop: "10px" }}>
                If you have a research project that you feel could benefit from AI technology, however unstructured and loosely defined, we can help you.
              </h5>

              <h5 class="medium-font text-left ">
                Below, you can find details of our work for a <strong>World Leading Beauty Conglomerate, a Top 10 Tech Company, and an American Bank.</strong>
              </h5>
            </div>
          </header>
          <div className="container" id="aging">
            <h3>Reading Consumer Emotions around Anti-Aging</h3>

            <div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
              <img src="img/emotion.png" alt="..." class="tech-img" style={{ marginTop: "0vh" }} />
              <div className="caption">
                <p>Emotion Recognition Signature in Anti-Aging Discussion</p>
              </div>
            </div>
            <div class="case_study_description">
              <p>
                <strong>The Challenge</strong>: One of the world’s leading beauty conglomerates sought to radically update their understanding of the anti-aging themes, opinions and pain points of female consumers.
              </p>
              <p>
                <strong>Our Approach</strong>: We created a dataset with text data from 60,000 skincare enthusiasts. Then, we used three complementary AI techniques: Topic Clustering, Emotion Recognition, and Trend Forecasting. By leveraging multiple approaches, we are able to obtain a more
                comprehensive understanding of the topic at hand.
              </p>
              <p>
                <strong>The Outcome</strong>: Our study yielded valuable insights into the emotional journey that consumers undergo during the aging process. Additionally, we pinpointed two specific ingredients that were causing confusion for consumers in anti-aging skincare products.
              </p>
            </div>
          </div>
          <hr />
          <div className="container" id="tech">
            <h3>Detecting Memorable Moments In Productivity Apps</h3>
            <div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
              <img src="img/NER.png" className="case-img nobord " alt="..." style={{ marginTop: "0vh" }} />
              <div className="caption">
                <p>Named Entity Recognition to Detect Peak Moments</p>
              </div>
            </div>
            <div class="case_study_description">
              <p>
                <strong>The Challenge</strong>: A top-10 global tech company wanted to detect peak emotional moments in the user experience of productivity apps. They sought to understand the factors that caused these memorable moments in competitor apps and how they could replicate it themselves.
              </p>
              <br />
              <p>
                <strong>Our Approach</strong>: To tackle this, we employed three AI techniques: Named Entity Recognition, Topic Clustering, and Emotion Recognition. Our system processed over 200,000 data points and extracted the 500 most highly emotional memories.
              </p>
              <br />
              <p>
                <strong>The Outcome</strong>: We detected peak moments across a wide range of environments in competitor apps, which was an exciting discovery for our client. We also identified that certain emotions, such as pride and relief, were often heightened. By analysing the features that
                contributed to these peaks, we provided recommendations to our client, which their engineering team is now acting on.
              </p>
            </div>
          </div>
          <hr />
          <div className="container" id="banking">
            <h3>Measuring and Improving the D&I Perception of a Bank</h3>
            <div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
              <img src="img/bank-deepdive.png" className="case-img nobord" alt="..." style={{ marginTop: "0vh" }} />
              <div className="caption">
                <p></p>
              </div>
            </div>
            <div class="case_study_description">
              <p>
                <strong>The Challenge</strong>: A leading American bank approached us to enhance its Diversity and Inclusion image and gain a better understanding of D&I in the banking industry. Our goal was to conduct a finance-focussed analysis of diversity-related conversations online.
              </p>
              <br />
              <p>
                <strong>Our Approach</strong>: We divided our approach into three scopes: Diversity Landscape, Brands Landscape, and D&I in Banking Deepdive. We leveraged 5 million data points and utilised three AI techniques: Topic Clustering, Sentiment Analysis, and Trend Forecasting.
              </p>
              <br />
              <p>
                <strong>The Outcome</strong>: Our research revealed that banks face a unique challenge in promoting themselves as diverse. We identified the primary issues that caused feelings of neglect to the BIPOC community in banking. Our findings informed a long-term improvement plan, which the
                bank is currently implementing to establish diverse practices internally and communicate effectively externally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
