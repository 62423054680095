import { faVolumeUp, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Definition = (props) => {
	return (
		<div id="definition" className=" text-center section" style={{ height: "100vh" }}>
			{/* Mobile */}
			<div className=" visible-xs def-text col-xs-12" style={{ paddingTop: 0 }}>
				<div>
					<span>
						<h1 style={{ fontFamily: "var(--secondary-font)", fontWeight: "bold" }}>Satori</h1>
					</span>
					<span>
						<p style={{ fontFamily: "var(--secondary-font)" }}>
							[suh-<strong>tawr</strong>-ee, -<strong>tohr</strong>-ee] <FontAwesomeIcon icon={faStar} />{" "}
							<FontAwesomeIcon icon={faVolumeUp} />
						</p>
					</span>
				</div>
				<div>
					<p>
						<i>Noun</i> Zen.
					</p>
				</div>
				<div>
					<ol>
						<li>1. Sudden Enlightenment.</li>
					</ol>
					<p>
						Example sentence: <i>“The road that leads to satori</i>”
					</p>
				</div>
			</div>
			{/* Desktop */}
			<div className="container hidden-xs def-text text-left">
				{/* <div class="row"> */}
				<div class="col-xs-10 desktop">
					<span style={{ display: "inline-block" }}>
						<h1 style={{ fontFamily: "var(--secondary-font)", fontWeight: "bold" }}>Satori</h1>
					</span>
					<span style={{ display: "inline-block", paddingLeft: 15 }}>
						<p style={{ fontFamily: "var(--secondary-font)" }}>
							[suh-<strong>tawr</strong>-ee, -<strong>tohr</strong>-ee] ✩ <FontAwesomeIcon icon={faVolumeUp} />
						</p>
					</span>
					<div>
						<p>
							<i>Noun</i> Zen.
						</p>
					</div>
					<div style={{ paddingLeft: 30 }}>
						<ol>
							<li>1. Sudden Enlightenment.</li>
						</ol>

						<p>
							Example sentence: <i>“The road that leads to satori</i>”
						</p>
					</div>
				</div>
				{/* <div class="col-xs-4">
						<p style={{ fontFamily: "var(--secondary-font)" }}>
							[suh-<strong>tawr</strong>-ee, -<strong>tohr</strong>-ee] ✩ <FontAwesomeIcon icon={faVolumeUp} />
						</p>
					</div> */}
				{/* </div> */}
			</div>
		</div>
	);
};
