import { Navigation } from "./components/navigation";

const Tech = () => {
  return (
    <div className="flex flex-col h-screen">
      <Navigation className="h-15" />
      {/* Desktop */}
      <div className="hidden-xs">
        <header id="header" style={{ height: "100vh" }}>
          <div class="col-md-10 col-md-offset-1 text-left" style={{ paddingTop: "20vh", marginBottom: "-30vh" }}>
            <h1 class="large-font">Our Tech</h1>
            <div class="col-md-6 ">
              <h5 class="text-left" style={{ paddingTop: "10px" }}>
                The purpose of Satori is to provide the Market Research sector with access to the academic frontier of AI.
              </h5>
              <h5 class="text-left " style={{ paddingTop: "10px" }}>
                With the rise of ChatGPT and other LLMs, an increasing number of companies in the Consumer Insights space are falsely claiming to be AI competent. There is a lot of noise and few cutting edge methods.
              </h5>

              <h5 class="text-left ">We don’t do marketing speech and buzzwords.</h5>

              <h5 class="text-left ">Everyone you will deal with at Satori is an AI specialist.</h5>
            </div>
          </div>
        </header>
        <div id="base">
          <div className="container body">
            <div class="myrow">
              <div className="col-sm-6 col-xs-12 leftside seg" style={{ marginTop: "-18vh", marginBottom: "-18vh", height: "105%" }}>
                <h3 class="large-font">Natural Language Processing</h3>
                <h5 class="text-left">Move Beyond Social Listening for a Comprehensive Text Analysis</h5>

                <div className="case_study_description">
                  <p>
                    <strong>What it does:</strong>
                  </p>
                  <p>
                    Natural Language Processing (NLP) allows you to extract fast, accurate insights from vast amounts of text content. This data will commonly be found on social media platforms, but may also be taken from survey responses, interview transcripts, or product reviews. Standard outputs
                    include: Topic Clustering breakdowns, Emotion Recognition to assess sentiment, and Trend Forecasting.
                  </p>
                  <br />
                  <p>
                    <strong>How it works:</strong>
                  </p>
                  <p>
                    We use the cutting edge in NLP technology to process the datasets that fit your target demographic. Our purpose built neural networks are able to cluster and name relevant subtopics in the given industry context, as well as measure the levels of 28 different human emotions that
                    can be conveyed through the text.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12 rightside seg" style={{ marginTop: "-20vh", marginBottom: "-20vh", height: "125%" }}>
                <div className="col-sm-12 col-xs-12">
                  <img src="img/bert.png" alt="..." class="tech-img" />
                  <div className="caption">
                    <p>Topic Clustering Visualisation using NLP methods</p>
                  </div>
                </div>
              </div>
              <div className="center-line-div top"></div>
            </div>
          </div>
          <div className="container body">
            <div class="myrow">
              <div className="col-sm-6 col-xs-12 leftside seg">
                <div className="col-sm-12 col-xs-12">
                  <img src="img/f1.gif" alt="..." className="tech-img" style={{ border: "unset" }} />
                  <div className="caption">
                    <p>Live Sponsorship Image Detection for Formula 1®</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12 rightside seg">
                <h3 class="visible-xs medium-font">Image and Video Analysis</h3>
                <h3 class="hidden-xs large-font">Image and Video Analysis</h3>
                <h5 class="visible-xs-block text-center ">Video and Image Content has Higher Engagement - Unlock those Insights</h5>
                <h5 class="hidden-xs text-left">Video and Image Content has Higher Engagement - Unlock those Insights</h5>

                <div className="case_study_description">
                  <p>
                    <strong>What it does:</strong>
                  </p>
                  <p>
                    In certain contexts, text data can only provide a limited understanding of consumer behavior online. By analysing visual content, we can help you uncover deeper insights and identify emerging trends that may not be apparent from text data alone. Our service allows you to organise
                    and measure the type of images and videos being shared at scale, for a particular industry, product, or company of interest.
                  </p>
                  <p>
                    <strong>How it works:</strong>
                  </p>
                  <p>
                    We use the most powerful AI methods in object recognition and image clustering. We use object recognition to track how a particular product or logo appears online, while image clustering enables us to categorise the type of images being shared in a particular online community.
                  </p>
                </div>
              </div>
              <div className="center-line-div mid"></div>
            </div>
          </div>
          <div className="container body">
            <div class="myrow">
              <div className="col-sm-6 col-xs-12 leftside seg">
                <h3 class="hidden-xs large-font">Network Analysis</h3>
                <h5 class="hidden-xs text-left">Map the Communities that your Target Audience Reside In</h5>

                <div className="case_study_description">
                  <p>
                    <strong>What it does:</strong>
                  </p>
                  <p>
                    Network Analysis allows you to explore which other spaces are associated with a given hobby, product, or industry. Most individuals split their online time between a number of different communities and interests; the data on their co-posting and co-engagement activity can be
                    extremely valuable.
                  </p>
                  <br />
                  <p>
                    <strong>How it works:</strong>
                  </p>
                  <p>
                    Using our AI algorithms, we can map out the digital footprint of your current and targeted customers. We start by selecting a base community and creating a set of users that are involved in your area of interest. Then, we track where else they are active on social media, the
                    other types of forums they use, and the posts they interact with. By building this out repeatedly, we are able to create a mapping with weighted edges illustrating the strength of the affinity.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-xs-12 rightside seg">
                <div className="col-sm-12 col-xs-12">
                  <img src="img/cod_network.png" alt="..." class="tech-img" />
                  <div className="caption">
                    <p>Illustrative Community Network for Gaming Franchise</p>
                  </div>
                </div>
              </div>
              <div className="center-line-div bot"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="visible-xs">
        <header id="header" style={{ height: "100vh" }}>
          <div class="text-left container mob" style={{ paddingTop: "15vh" }}>
            <h1 class="medium-font text-center">Our Tech</h1>

            <h5 class="medium-font text-left " style={{ paddingTop: "10px" }}>
              The purpose of Satori is to provide the Market Research sector with access to the academic frontier of AI.
            </h5>
            <h5 class="medium-font text-left " style={{ paddingTop: "10px" }}>
              With the rise of ChatGPT and other LLMs, an increasing number of companies in the Consumer Insights space are falsely claiming to be AI competent. There is a lot of noise and few cutting edge methods.
            </h5>

            <h5 class="medium-font text-left " style={{ paddingTop: "10px" }}>
              We don’t do marketing speech and buzzwords.
            </h5>

            <h5 class="medium-font text-left " style={{ paddingTop: "10px" }}>
              Everyone you will deal with at Satori is an AI specialist.
            </h5>
          </div>
        </header>
        <div className="container">
          <h3>Natural Language Processing</h3>
          <h5>Move Beyond Social Listening for a Comprehensive Text Analysis</h5>
          <div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
            <img src="img/bert.png" alt="..." class="tech-img" style={{ marginTop: "0vh" }} />
            <div className="caption">
              <p>Topic Clustering Visualisation using NLP methods</p>
            </div>
          </div>
          <div className="case_study_description">
            <p>
              <strong>What it does:</strong>
            </p>
            <p>
              Natural Language Processing (NLP) allows you to extract fast, accurate insights from vast amounts of text content. This data will commonly be found on social media platforms, but may also be taken from survey responses, interview transcripts, or product reviews. Standard outputs
              include: Topic Clustering breakdowns, Emotion Recognition to assess sentiment, and Trend Forecasting.
            </p>
            <br />
            <p>
              <strong>How it works:</strong>
            </p>
            <p>
              We use the cutting edge in NLP technology to process the datasets that fit your target demographic. Our purpose built neural networks are able to cluster and name relevant subtopics in the given industry context, as well as measure the levels of 28 different human emotions that can be
              conveyed through the text.
            </p>
          </div>
        </div>
        <hr />
        <div className="container">
          <h3>Image & Video Analysis</h3>
          <h5>Video and Image Content has Higher Engagement - Unlock those Insights</h5>

          <div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
            <img src="img/f1.gif" alt="..." class="tech-img" style={{ marginTop: "0vh", border: "unset" }} />
            <div className="caption">
              <p>Live Sponsorship Image Detection for Formula 1®</p>
            </div>
          </div>
          <div className="case_study_description">
            <p>
              <strong>What it does:</strong>
            </p>
            <p>
              In certain contexts, text data can only provide a limited understanding of consumer behavior online. By analysing visual content, we can help you uncover deeper insights and identify emerging trends that may not be apparent from text data alone. Our service allows you to organise and
              measure the type of images and videos being shared at scale, for a particular industry, product, or company of interest.
            </p>
            <br />
            <p>
              <strong>How it works:</strong>
            </p>
            <p>We use the most powerful AI methods in object recognition and image clustering. We use object recognition to track how a particular product or logo appears online, while image clustering enables us to categorise the type of images being shared in a particular online community.</p>
          </div>
        </div>
        <hr />
        <div className="container">
          <h3>Network Analysis</h3>
          <h5>Map the Communities that your Target Audience Reside In</h5>
          <div className="col-sm-6 col-xs-12" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
            <img src="img/cod_network.png" alt="..." class="tech-img" style={{ marginTop: "0vh" }} />
            <div className="caption">
              <p>Illustrative Community Network for Gaming Franchise</p>
            </div>
          </div>
          <div className="case_study_description">
            <p>
              <strong>What it does:</strong>
            </p>
            <p>
              Network Analysis allows you to explore which other spaces are associated with a given hobby, product, or industry. Most individuals split their online time between a number of different communities and interests; the data on their co-posting and co-engagement activity can be extremely
              valuable.
            </p>
            <br />
            <p>
              <strong>How it works:</strong>
            </p>
            <p>
              Using our AI algorithms, we can map out the digital footprint of your current and targeted customers. We start by selecting a base community and creating a set of users that are involved in your area of interest. Then, we track where else they are active on social media, the other
              types of forums they use, and the posts they interact with. By building this out repeatedly, we are able to create a mapping with weighted edges illustrating the strength of the affinity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
