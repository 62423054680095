import React, { useEffect, useState } from "react";
import { Navigation } from "./components/navigation";
import { useParams } from "react-router-dom";
import JsonData from "./data/data.json";
import "./index.css";

const BlogPost = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    let match = JsonData.Blogs.filter((d) => d.id === id)[0];
    setBlogData(match);
  }, [id]);

  if (!blogData) return <div>Loading...</div>;

  return (
    <div id="blog" className="container">
      <Navigation className="h-15" />
      <div className="hidden-xs">
        <div className="col-md-8 col-md-offset-2 text-left" style={{ paddingTop: "10vh", marginBottom: "-30vh" }}>
          {blogData.content.sections.map((section, index) => (
            <div key={index}>
              {section.type === "header" && React.createElement(`h${section.level}`, null, section.text)}
              {section.type === "paragraph" && <p dangerouslySetInnerHTML={{ __html: section.text }} />}
              {section.type === "image" && (
                <div className="image-wrapper">
                  <img src={`/img/${section.image}`} alt="Blog Image" className="blogImgSmall" />
                </div>
              )}
              {section.type === "list" && (
                <div className="list-container col-md-12">
                  <ul>
                    {section.items.map((item, idx) => (
                      <li key={idx} dangerouslySetInnerHTML={{ __html: item }}></li>
                    ))}
                  </ul>
                </div>
              )}
              {section.type === "code" && <code>{section.text}</code>}
              {/* New section type for image on the right and text on the left */}
              {section.type === "imageText" && (
                <div className="flex flex-col md:flex-row items-center md:items-start my-8">
                  {/* Text on the left */}
                  <div className="flex-1 text-left pr-4 mb-4 md:mb-0">
                    <p className="text-lg text-gray-700" dangerouslySetInnerHTML={{ __html: section.text }} />
                  </div>

                  {/* Image on the right */}
                  <div className="flex-shrink-0 md:w-1/3">
                    <img src={`/img/${section.image}`} alt="Blog Image" className="w-full max-w-xs md:max-w-sm rounded" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="visible-xs">
        <div className="col-md-8" style={{ paddingTop: "10vh", marginBottom: "-30vh" }}>
          {blogData.content.sections.map((section, index) => (
            <div key={index}>
              {section.type === "header" && React.createElement(`h${section.level}`, null, section.text)}
              {section.type === "paragraph" && <p dangerouslySetInnerHTML={{ __html: section.text }} />}
              {section.type === "image" && (
                <div className="image-wrapper">
                  <img src={`/img/${section.image}`} alt="Blog Image" className="blogImgSmall" />
                </div>
              )}
              {section.type === "list" && (
                <div className="list-container col-md-12">
                  <ul>
                    {section.items.map((item, idx) => (
                      <li key={idx} dangerouslySetInnerHTML={{ __html: item }}></li>
                    ))}
                  </ul>
                </div>
              )}
              {section.type === "code" && <code>{section.text}</code>}
              {section.type === "imageText" && (
                <div className="flex flex-col items-center my-8">
                  {/* Image centered */}
                  <div className="flex justify-center mb-4">
                    <img src={`/img/${section.image}`} alt="Blog Image" className="w-full max-w-xs rounded" />
                  </div>

                  {/* Text below the image */}
                  <div className="">
                    <p className="text-lg text-gray-700" dangerouslySetInnerHTML={{ __html: section.text }} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
